.video {
  position: absolute;
  z-index: 10;
  background: rgba(255,255,255,0.7);

  &.is-hidden {
    display: none;
  }

  &__embed {
    width: 100%;
    height: 100%;
  }

}