@import "../../global/scss/vendor/reset-css";
@import "../../global/scss/variables";
@import "../../global/scss/mixins/breakpoint";
@import "../../global/scss/mixins/browser-targeting";


// img sizes
.desktop {
  .img-1 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-2 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-3 {
    // w: 3998px, h:2953px
    padding-top: 73.86%;
  }

  .img-4 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-5 {
    // w: 3998px, h:6496px
    padding-top: 162.48%;
  }

  .img-6 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-7 {
    // w: 3998px, h:2953px
    padding-top: 73.86%;
  }

  .img-8 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-9 {
    // w: 3998px, h:2953px
    padding-top: 73.86%;
  }

  .img-10 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-11 {
    // w: 3998px, h:2953px
    padding-top: 73.86%;
  }

  .img-12 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-13 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }
}

.tablet, .mobile {
  .img-1 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-2 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-3 {
    // w: 2344px, h:4583px
    padding-top: 195.52%;
  }

  .img-4 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-5 {
    // w: 2344px, h:14583px
    padding-top: 622.14%;
  }

  .img-6 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-7 {
    // w: 2344px, h:5833px
    padding-top: 248.84%;
  }

  .img-8 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-9 {
    // w: 2344px, h:4583px
    padding-top: 195.52%;
  }

  .img-10 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-11 {
    // w: 2344px, h:6250px
    padding-top: 266.63%;
  }

  .img-12 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-13 {
    // w: 2344px, h:5000px
    padding-top: 216.31%;
  }
}

@import "../../global/scss/global";
@import "../../global/scss/video";
@import "../../global/scss/confirm-container";
@import "../../global/scss/button";
@import "../../global/scss/link";

//@import "../../global/scss/debug";